import { fetch } from '../../node_modules/react-ui-basics/HttpTools.js';

// const base = 'https://unnotcher.duckdns.org'
const base = '';
const upload = (params, onProgress, provideCancel) => fetch(base + '/upload', {
    params,
    method: "POST",
    multipart: true,
    responseType: "blob",
    onProgress,
    provideCancel,
});

export { upload };
