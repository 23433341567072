import { jsxs as jsxRuntime_2, jsx as jsxRuntime_1 } from 'preact/jsx-runtime';
import { classNames } from '../../node_modules/react-ui-basics/Tools.js';
import FormUploadProgress$1 from '../../node_modules/react-ui-basics/FormUploadProgress.js';
import DropFileInput$1 from '../../node_modules/react-ui-basics/DropFileInput.js';
import { css as u } from '../../node_modules/goober/dist/goober.esm.js';
import { upload } from '../services/NetworkService.js';
import { useState as react_8 } from 'preact/compat';

const styles = u `
  &.App {
    min-height: 100vh;
    padding: 50px;

    .Dropzone {
      min-height: 200px;
    }
  }
`;
const saveFile = (blob, filename) => {
    const blobURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
var App = () => {
    const [total, setTotal] = react_8(0);
    const [uploaded, setUploaded] = react_8(0);
    const [progress, setProgress] = react_8(0);
    const [cancel, setCancel] = react_8();
    return jsxRuntime_2("div", { className: classNames("App", styles), children: [jsxRuntime_1(DropFileInput$1, { multiple: false, icon: 'upload', label: 'Choose image to unnotch!', accept: "image/*", onDrop: async (files) => {
                    console.log(files);
                    setUploaded(0);
                    setProgress(0);
                    const file = files[0];
                    setTotal(file.size);
                    try {
                        const response = await upload({
                            file: file,
                            name: file.name,
                            width: 3024,
                            height: 1964,
                        }, (e) => {
                            setUploaded(e.loaded);
                            setTotal(e.total);
                            setProgress(e.loaded / e.total * 100);
                        }, cancel => setCancel(prevState => () => {
                            cancel();
                            setTotal(0);
                        }));
                        saveFile(response, file.name);
                    }
                    finally {
                        setTotal(0);
                    }
                } }), !!total && jsxRuntime_1(FormUploadProgress$1, { processingLabel: 'Processing..', cancelLabel: 'Cancel', value: progress, loaded: uploaded, total: total, cancel: cancel })] });
};

export { App as default };
